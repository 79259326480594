import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DateTimePicker } from "@material-ui/pickers";

const useStyles = makeStyles(theme => ({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    margin: {
      marginRight: theme.spacing(1),
    },
  }));

export default function RegisterModal({ Axios, isRegistration, plates, onClose }) {
    const classes = useStyles();

    const [expiry, setExpiry] = useState(null);

    const deregisterVehicles = async () => {
        console.log(plates);
        const deregRes = await Axios.post('/deregister', {
          plates: plates.map(p => p.plate),
          date: expiry
        });
        console.log(deregRes);
        onClose()
      };
    
    const registerVehicles = async () => {
        console.log(plates);
        const regRes = await Axios.post('/register', {
          plates: plates.map(p => p.plate),
          date: expiry
        });
        console.log(regRes);
        onClose()
    };

    return (
        <Dialog open={true} onClose={onClose} aria-labelledby="Vehicle Modal">
            <DialogTitle id="form-dialog-title">
                {isRegistration ? 'Register Vehicles' : 'Deregister Vehicles'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    The plates { plates.map(p => p.plate).join(', ') } will be {isRegistration ? 'registered' : 'deregistered'}.
                </DialogContentText>
                <DateTimePicker 
                    label="Scheduled Date"
                    disablePast={true}
                    value={expiry}
                    onChange={(exp) => setExpiry(exp)}
                    inputVariant="outlined"
                    margin="normal"
                    className={classes.textField}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                {isRegistration ? 
                <Button onClick={() => registerVehicles()} color="primary">
                    Register
                </Button> : 
                <Button onClick={() => deregisterVehicles()} color="primary">
                    Deregister
                </Button>}
            </DialogActions>
        </Dialog>
    )
}