import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import AccountModal from './modals/AccountModal'

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 48+'px'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    margin: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
}));

const Settings = function ({ Axios, enqueueSnackbar }) {
    const classes = useStyles();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const [selectedAccount, setSelectedAccount] = useState(null);
    const [newAccount, setNewAccount] = useState({
        email: '',
        password: ''
    });
  
    const loadUser = async () => {
        setLoading(true);
        try {
            const userRes = await Axios.get('/user');
            setUser(userRes.data);
        } catch (err) {
            enqueueSnackbar('Failed to load user data. Check network connection.', {variant: 'warning'});
        }
        setLoading(false)
    };

    const deleteAccount = async (id) => {
        try {
            setLoading(true);
            const deleteRes = await Axios.delete(`/accounts/${id}`);
            console.log(deleteRes)
            await loadUser()
        } catch (err) {
            enqueueSnackbar('Failed to delete account. Check network connection.', {variant: 'warning'});
        }
        setLoading(false);
    };

    const addAccount = async () => {
        try {
            setLoading(true);
            const addRes = await Axios.post(`/accounts`, newAccount);
            console.log(addRes)
            await loadUser()
        } catch (err) {
            enqueueSnackbar('Failed to add account. Check network connection.', {variant: 'warning'});
        }
        setLoading(false);
    };
    
    useEffect(() => {
        loadUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = name => event => {
        setNewAccount({ ...newAccount, [name]: event.target.value });
    };

    return (
        <>
        {openModal && <AccountModal Axios={Axios} account={selectedAccount} onClose={() => {
            setOpenModal(false);
            setSelectedAccount(null);
        }} />}
        <form className={classes.container} noValidate autoComplete="off">
            <Grid container justify="flex-start" direction="column">
                <Typography variant="h4">
                    Settings
                </Typography>
                {loading && <CircularProgress
                    className={classes.margin}
                />}
                {user && <>
                    <TextField
                        id="username"
                        label="Rtrak Username"
                        className={classes.textField}
                        value={user.email}
                        disabled
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        type="email"
                    />
                    <Typography variant="body1" style={{marginTop: 12+'px', marginBottom: 12+'px'}}>
                        The accounts below are used on your behalf to login to the EzyReg Vehicle Management Portal.
                    </Typography>
                    <List className={classes.root}>
                        {user.ezyaccounts.map(account => {
                            const labelId = `checkbox-list-label-${account.email}`;

                            return (
                            <ListItem key={account.email}>
                                <ListItemText id={labelId} primary={`${account.email}`} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="edit" onClick={() => {
                                        setSelectedAccount(account);
                                        setOpenModal(true);
                                    }}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={() => {
                                        deleteAccount(account.id)
                                    }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            );
                        })}
                    </List>

                    <Grid direction="column">
                        <Typography variant="h6" style={{marginTop: 12+'px'}}>
                            Add new EzyReg Account
                        </Typography>
                        <Grid container justify="flex-start" direction="row">
                            <TextField
                                id="filled-username"
                                label="EzyReg Username"
                                className={classes.textField}
                                value={newAccount.email}
                                onChange={handleChange('email')}
                                margin="normal"
                                variant="outlined"
                                type="email"
                            />
                            <TextField
                                id="filled-password"
                                label="EzyReg Password"
                                className={classes.textField}
                                value={newAccount.password}
                                onChange={handleChange('password')}
                                margin="normal"
                                variant="outlined"
                                type="password"
                            />
                            <Fab
                                disabled={loading}
                                variant="extended"
                                size="large"
                                color="primary"
                                aria-label="add"
                                className={classes.margin}
                                style={{width: 150+'px'}}
                                onClick={() => addAccount()}
                            >
                                Add Account
                            </Fab>
                        </Grid>

                        
                    </Grid>
                </>}
            </Grid>
        </form>
        </>
    )
}

export default withSnackbar(Settings);