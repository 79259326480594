import React, { forwardRef, useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { DateTime } from 'luxon';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { amber } from '@material-ui/core/colors';

import Grid from '@material-ui/core/Grid';

import IconButton from '@material-ui/core/IconButton';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import Info from '@material-ui/icons/Info';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

import RegisterModal from './modals/RegisterModal'
import VehicleModal from './modals/VehicleModal'
import FleetModal from './modals/FleetModal'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    AssignmentTurnedIn: forwardRef((props, ref) => <AssignmentTurnedIn {...props} ref={ref} />),
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 48+'px'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  margin: {
    marginRight: theme.spacing(1),
  },
  snackbar: {
    margin: theme.spacing(1),
    backgroundColor: amber[900]
  },
}));

const Vehicles = function ({ Axios, enqueueSnackbar }) {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState(null);
  const [activity, setActivity] = useState(null);

  const [selectedPlates, setSelectedPlates] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const loadVehicles = async () => {
    setLoading(true);
    const vehicleRes = await Axios.get('/vehicles');
    setVehicles(vehicleRes.data);
    const activityRes = await Axios.get('/pendingactivity');
    setActivity(activityRes.data);
    console.log(activityRes.data)
    setLoading(false)
  };

  useEffect(() => {
    loadVehicles()
      .then(() => setLoading(false))
      .catch(() => {
        setLoading(false);
        enqueueSnackbar('Failed to load vehicles. Check network connection.', {variant: 'warning'});
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function handleClickOpen() {
  //   setOpen(true);
  // }

  const handleInfo = async (rowData) => {
    try {
      const vehicleRes = await Axios.get(`/vehicles/${rowData.plate}`);
      setSelectedVehicle(vehicleRes.data);
      setOpenModal('vehicle');
    } catch (err) {
      enqueueSnackbar('Failed to load vehicle. Check network connection.', {variant: 'warning'});
    }
  }

  const handleFleet = async (rowData) => {
    try {
      const vehicleRes = await Axios.get(`/vehicles/${rowData.plate}`);
      setSelectedVehicle(vehicleRes.data);
      setOpenModal('fleet');
    } catch (err) {
      enqueueSnackbar('Failed to load vehicle. Check network connection.', {variant: 'warning'});
    }
  }

  const refresh = async () => {
    setLoading(true);
    try {
      const vehicleRes = await Axios.post('/refresh');
      setVehicles(vehicleRes.data);
    } catch (err) {
      enqueueSnackbar('Failed to refresh vehicles. Check network connection.', {variant: 'warning'});
    }
    setLoading(false);
  };

  return (
    <div className={classes.container}>
      {openModal === 'register' && <RegisterModal Axios={Axios} isRegistration={true} plates={selectedPlates} onClose={() => setOpenModal(null)} />}
      {openModal === 'deregister' && <RegisterModal Axios={Axios} isRegistration={false} plates={selectedPlates} onClose={() => setOpenModal(null)} />}
      {openModal === 'vehicle' && <VehicleModal Axios={Axios} vehicle={selectedVehicle} onClose={() => setOpenModal(null)} />}
      {openModal === 'fleet' && <FleetModal Axios={Axios} vehicle={selectedVehicle} onClose={({r}) => {
        setOpenModal(null)
        if (r) loadVehicles()
      }} />}

      <Grid container justify="flex-start" direction="column">
        {activity && <>{activity.length > 0 && <SnackbarContent
          className={classes.snackbar}
          message={
            activity.map((a => { 
              let dt = DateTime.fromISO(a.job_data.date)
              dt = dt.toLocaleString(DateTime.DATETIME_SHORT)
              return (<p>
              {a.event === 'deregistrations' && <>Deregistration scheduled for {a.job_data.plates.join(', ')} at {dt}</>}
              {a.event === 'registrations' && <>Registration scheduled for {a.job_data.plates.join(', ')} at {dt}</>}
            </p>)}))
          }
        />}</>}
        <Fab
          disabled={loading}
          variant='extended'
          size='small'
          aria-label='add'
          className={classes.margin}
          style={{marginTop: 12+'px', marginBottom: 12+'px',marginLeft: 8+'px', width: 240+'px'}}
          onClick={refresh}
        >
          Refresh
        </Fab>
        <MaterialTable
          isLoading={loading}
          icons={tableIcons}
          title="Vehicles"
          //onRowClick={(e, row) => handleClickOpen()}
          columns={[
            { title: 'Plate', field: 'plate' },
            { title: 'Fleet No', field: 'fleet_no'},
            { title: 'Body', field: 'body' },
            { title: 'Make', field: 'make' },
            { 
              title: 'Expiry',
              field: 'expiry_date',
              type: 'date',
              defaultSort: 'asc',
              render: rowData => {
                let dt = DateTime.fromISO(rowData.expiry_date)
                return dt.toLocaleString(DateTime.DATETIME_SHORT)
              }
            },
            {
              title: 'Expired',
              field: 'expired',
              render: rowData => {
                if (rowData.expired){
                  return <p style={{fontWeight: 600, color: red[500]}}>Expired</p>
                } else {
                  return <p style={{fontWeight: 600, color: green[500]}}>Registered</p>
                }                
              }
            },
            { 
              title: 'Last Updated',
              field: 'updated_at',
              type: 'date',
              render: rowData => {
                let dt = DateTime.fromISO(rowData.updated_at)
                return dt.toLocaleString(DateTime.DATETIME_SHORT)
              }
            },
            { 
              title: 'Actions',
              field: '',
              render: rowData => 
              <>
              <IconButton aria-label="Info" onClick={() => handleInfo(rowData)}>
                <Info />
              </IconButton>
              <IconButton aria-label="Edit" onClick={() => handleFleet(rowData)}>
                <Edit />
              </IconButton>
              </>
            },
          ]}
          data={vehicles || []}    
          options={{
            search: true,
            selection: true,
            pageSize: 10,
            exportButton: true,
            showSelectAllCheckbox: false
          }}
          actions={[
            {
              tooltip: 'Deregister Vehicles',
              icon: () => <DeleteOutline />,
              onClick: (evt, data) => {
                setSelectedPlates(data);
                setOpenModal('deregister');
              }
            },
            {
              tooltip: 'Register Vehicles',
              icon: () => <AssignmentTurnedIn />,
              onClick: (evt, data) => {
                setSelectedPlates(data);
                setOpenModal('register');
              }
            }
          ]}
        />
      </Grid>
    </div>
  )
}

export default withSnackbar(Vehicles);