import React, { forwardRef, useState, useEffect } from 'react';
import { withSnackbar } from 'notistack';
import { DateTime } from 'luxon';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    AssignmentTurnedIn: forwardRef((props, ref) => <AssignmentTurnedIn {...props} ref={ref} />),
};

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 48+'px'
    },
    margin: {
        marginRight: theme.spacing(1),
    },
}));

const ActivityLog = function ({ Axios, enqueueSnackbar }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [activity, setActivity] = useState(null);

    const retrieveActivity = async () => {
        setLoading(true);
        try {
            const activityRes = await Axios.get('/activity')
            setActivity(activityRes.data);
        } catch (err) {
            enqueueSnackbar('Failed to load activity. Check network connection.', {variant: 'warning'});
        }
        setLoading(false);
    };

    useEffect(() => {
        retrieveActivity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    return (
        <div className={classes.container}>
            <Grid container justify="flex-start" direction="column">
                <Typography variant="h4">
                    Activity Log
                </Typography>
                <Typography variant="body1" style={{marginTop: 12+'px', marginBottom: 12+'px'}}>
                    This is a list of all registration and de-registration actions that have been performed within RTrak.
                </Typography>
                <MaterialTable
                    isLoading={loading}
                    icons={tableIcons}
                    title="Activity"
                    //onRowClick={(e, row) => handleClickOpen()}
                    columns={[
                        { title: 'Event', field: 'event' },
                        { title: 'Status', field: 'job_status' },
                        { 
                            title: 'Started at',
                            field: 'created_at',
                            type: 'date',
                            defaultSort: 'desc',
                            render: rowData => {
                                let dt = DateTime.fromISO(rowData.created_at)
                                return dt.toLocaleString(DateTime.DATETIME_SHORT)
                            }
                        },
                        {
                            title: 'Activity Data',
                            field: 'job_data',
                            render: rowData => {
                                const plates = rowData.job_data.plates ? rowData.job_data.plates.join() : ""
                                let scheduled = rowData.job_data.date ? DateTime.fromISO(rowData.job_data.date) : 'N/A';
                                if (scheduled !== 'N/A') {
                                    scheduled = scheduled.toLocaleString(DateTime.DATETIME_SHORT)
                                }
                                return (
                                    <>
                                    <p><b>Plates:</b> {plates}</p>
                                    <p><b>Scheduled on:</b> {scheduled}</p>
                                    </>
                                )
                            }
                        },
                        {
                            title: 'Activity Result',
                            field: 'job_result',
                            render: rowData => {
                                let plates = []
                                for (var plate in rowData.job_result){
                                    plates.push({
                                        ...rowData.job_result[plate],
                                        plate: plate
                                    })
                                }                                
                                return plates.map(plate =>
                                    <>
                                    <p>
                                        <b>{plate.plate}</b> - {plate.status} {plate.saving ? (<><b>Refund:</b> {plate.saving} </>) : ''} {plate.amount ? (<><b>Amount:</b> {plate.amount} </>) : ''} {plate.summaryFile && <a rel="noopener noreferrer" style={{color: `#527FFF`}} target="_blank" href={plate.summaryFile}>Transaction Summary</a>}
                                    </p>
                                    </>
                                )
                            }
                        },
                    ]}
                    data={activity || []}    
                    options={{
                        search: true,
                        pageSize: 10,
                        exportButton: true
                    }}
                />
            </Grid>
        </div>
    )
};

export default withSnackbar(ActivityLog);