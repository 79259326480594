import React from 'react';
import { Router, navigate } from "@reach/router";
import Amplify, { Auth } from 'aws-amplify';
import {
  SignIn, ConfirmSignIn, RequireNewPassword, VerifyContact, withAuthenticator
} from 'aws-amplify-react';
import axios from 'axios';

import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';

import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

import { makeStyles } from '@material-ui/core/styles';
import './App.css';

import Vehicles from './components/Vehicles';
import Settings from './components/Settings';
import ActivityLog from './components/ActivityLog';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

Amplify.configure({
  Auth: {
    region: 'ap-southeast-2',
    userPoolId: 'ap-southeast-2_Ry88K5rZl',
    userPoolWebClientId: '4vko2q893hlrtdb7avq7ip4lqq'
  }
});

const Axios = axios.create({
  baseURL: 'https://api.rtrakapp.com',
  //baseURL: 'http://localhost:8081',
  timeout: 120000
});

Axios.interceptors.request.use(async (config) => {
  const session = await Auth.currentSession();
  config.headers.Authorization = `Bearer ${session.idToken.jwtToken}`;
  return config;
}, function (error) {
  return Promise.reject(error);
});

const App = ({ children }) => {
  const classes = useStyles();
  return (
    <>
    <SnackbarProvider maxSnack={3}>
      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={() => navigate('/')} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <HomeIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            RTrak
          </Typography>
          <Button color="inherit" onClick={() => navigate('/settings')}>Settings</Button>
          <Button color="inherit" onClick={() => navigate('/activity')}>Activity</Button>
          <Button color="inherit" onClick={() => Auth.signOut({global: true})}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <Router>
              <Vehicles path="/" Axios={Axios} />
              <ActivityLog path="/activity" Axios={Axios} />
              <Settings path="/settings" Axios={Axios} />
            </Router>
          </MuiPickersUtilsProvider>
      </Container>
    </SnackbarProvider>
    </>
  )
};

export default withAuthenticator(App, false, [
  <SignIn />, <ConfirmSignIn />, <RequireNewPassword />, <VerifyContact />
]);