import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    margin: {
      marginRight: theme.spacing(1),
    },
  }));

export default function FleetModal({ Axios, vehicle, onClose }) {
    const classes = useStyles();
    const [fleetNo, setFleetNo] = useState('');

    const handleChange = name => event => {
        setFleetNo(event.target.value);
    };

    const updateVehicle = async () => {
        const updateRes = await Axios.put(`/vehicles/${vehicle.plate}`, {
            fleet_no: fleetNo
        });
        console.log(updateRes);
        onClose({r: true})
    };

    return (
        <Dialog open={true} onClose={onClose} aria-labelledby="Fleet Modal">
            <DialogTitle id="form-dialog-title">
                Edit {vehicle.plate}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Update the Fleet Number for {vehicle.plate}
                </DialogContentText>
                <TextField
                    id="filled-fleet"
                    label="Fleet No"
                    className={classes.textField}
                    value={fleetNo}
                    onChange={handleChange('fleetNo')}
                    margin="normal"
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button disabled={!fleetNo || fleetNo.length < 1} onClick={() => updateVehicle()} color="primary">
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    )
}