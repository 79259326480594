import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    margin: {
      marginRight: theme.spacing(1),
    },
  }));

export default function AccountModal({ Axios, account, onClose }) {
    const classes = useStyles();
    const [password, setPassword] = useState('');

    const handleChange = name => event => {
        setPassword(event.target.value);
    };

    const updateAccount = async () => {
        const updateRes = await Axios.put(`/accounts/${account.id}`, {
            password: password
        });
        console.log(updateRes);
        onClose()
    };

    return (
        <Dialog open={true} onClose={onClose} aria-labelledby="Account Modal">
            <DialogTitle id="form-dialog-title">
                Edit {account.email}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Update the password to connect to EzyReg Account
                </DialogContentText>
                <TextField
                    id="filled-password"
                    label="EzyReg Password"
                    className={classes.textField}
                    value={password}
                    onChange={handleChange('password')}
                    margin="normal"
                    variant="outlined"
                    type="password"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button disabled={!password || password.length < 3} onClick={() => updateAccount()} color="primary">
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    )
}