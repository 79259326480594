import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';

//import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    AssignmentTurnedIn: forwardRef((props, ref) => <AssignmentTurnedIn {...props} ref={ref} />),
};

// const useStyles = makeStyles(theme => ({
//     textField: {
//       marginLeft: theme.spacing(1),
//       marginRight: theme.spacing(1),
//     },
//     margin: {
//       marginRight: theme.spacing(1),
//     },
//   }));

export default function VehicleModal({ Axios, vehicle, onClose }) {
    //const classes = useStyles();

    const updateVehicle = async () => {

    }

    return (
        <Dialog fullWidth={true}
        maxWidth={'md'} open={true} onClose={onClose} aria-labelledby="Vehicle Modal">
            <DialogTitle id="form-dialog-title">
                {vehicle.plate}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    
                </DialogContentText>
                <MaterialTable
                    icons={tableIcons}
                    title="Vehicle Activity"
                    //onRowClick={(e, row) => handleClickOpen()}
                    columns={[
                        { title: 'Event', field: 'event' },
                        { title: 'Status', field: 'job_status' },
                        { 
                            title: 'Started at',
                            field: 'created_at',
                            type: 'date',
                            defaultSort: 'desc'
                        },
                    ]}
                    data={vehicle.activitylogs || []}    
                    options={{
                        search: false,
                        pageSize: 5
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
                
                <Button onClick={() => updateVehicle()} color="primary">
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    )
}